<template>
  <div class="mistake">
    <div>
      <v-img
        :src="url"
        max-height="511"
        max-width="966"
        style="margin-left: auto;
    margin-right: auto;
}"
      ></v-img>
    </div>
    <p style="color: #354052; text-align: center" class="wordsize">
      {{ msg }}
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      arr: ["403", "404", "500", "zwu"],
      arrmsg: [
        "抱歉您没有访问权限",
        "抱歉，您访问的网页没有找到",
        "抱歉，服务器出了点小问题",
        "暂无数据",
      ],
      url: "",
      msg: "抱歉你没有访问权限",
    };
  },
  created() {
    let i = this.arr.indexOf(this.$route.params.id);
    this.url = require("@/assets/img/" + this.arr[i] + ".png");
    this.msg = this.arrmsg[i];
  },
};
</script>

<style lang="scss">
.mistake {
  padding-top: 120px;
}
</style>